.PrivacyPolicy, .Terms{
    display: flex;
    flex-direction: column;
    background-color: rgba(21, 23, 25, 0.7450980392);
    align-items: center;
    padding: 0px 60px;
    margin: 50px 10vw;
    p{
        font-size: 16px !important;
    }
    h1{
        font-size: 36px !important;
        margin-top: 20px;
    }
    h2{
        font-size: 26px !important;
    }
    h3{
        font-size: 20px !important;
    }
    li{
        color: #9BA9B4;
    }
    a{
        color: #F08138;
    }
}