.Footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(21, 23, 25, 0.7450980392);
    border-top: 2px solid #93552B;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
    ul{
        display: flex;
        gap: 30px;
        margin: 5px 0px;
        li{
            color: #9BA9B4;
            a{
                color: #9BA9B4;
                text-decoration: none;
            }
        }
    }
    p{
        margin-top: 10px;
        font-size: 16px !important;
    }
}