.Admin{
    margin: 20vh 0px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: auto ;
    h1{
        grid-column: 2;
    }
    nav{
        width: 60%;
        grid-column: 1;
        grid-row: 2;
        position: relative;
        z-index: 1;
        background-color: #151719be;
        align-self: start;
        justify-self: center;
        &:before{
            content: "";
            background:
            linear-gradient(to right, #f0823893, 2px, transparent 2px) 0 2px,
            linear-gradient(to left, #f0823893, 2px, transparent 2px) 100% 100%,
            linear-gradient(to bottom, #f0823893, 2px, transparent 2px) 0 0px,
            linear-gradient(to top, #f0823893, 2px, transparent 2px) calc(100% - 2px) 100%;
            background-repeat: no-repeat;
            background-size: 75px 50px;
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
        ul{
            text-align: center;
            padding: 2px 0px;
            li{
                margin: 0px 2px !important;
                color: #9BA9B4;
                border-radius: 0px !important;
            }
            .button-a{
                &:hover{
                    color: #FFF;
                    background: #33363A;
                }
            }
        }
    }
    .wrapper{
        grid-column: 2;
        grid-row: 2;
        display: flex;
        justify-content: center;
        align-items: flex-start
        table{
            width: 100%;
          }
          table,td {
              border: 1px solid #333;
              color: #9BA9B4;
              text-align: center;
          }
          td{
            padding: 5px 5px;
            background-color: #151719be;
            user-select: text;
          }
          th{
            padding: 5px 5px;
            background: #33363A;
          }
        
          thead,
          tfoot {
              background-color: #333;
              color: #fff;
          }
    }
}
.Users, .Players{
    margin-top: 10vh;
}
.Keys, .General{
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .header-key{
        display: flex;
        gap: 10px;
        div{
            background: #33363A;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 15vw;
            p{
                color: #fff;
                font-size: 16px;
                padding: 0px 15px;
                user-select: text !important;
                z-index: 1;
            }            
            &:before{
                z-index: 0;
                content: '';
                position: absolute;
                top: -3px;
                left: -3px;
                right: -3px;
                bottom: -3px;
                border: #333 1px solid;
            }
        }
    }
    .table{
        display: flex;
        flex-direction: column;
    }
    input[type="checkbox"] {
        appearance: none;
        background-color: #333;
        margin: auto;
        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.12em solid #fff;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
      }
      input[type="checkbox"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background-color: #F08138;
        border-radius: 0.1em;
      }
      input[type="checkbox"]:checked::before {
        transform: scale(1);
      }




      
      .form-control + .form-control {
        margin-top: 1em;
      }
}
.General{
    .header-key{
        flex-direction: column;
        min-width: 25vw;
        div{
            width: auto;
            padding: 10px;
            p{
                padding: 0px 10px;
            }
        }
        .button-group{
            display: flex;
            justify-content: center;
            button{
                z-index: 1;
            }
        }
    }
}